// import React from 'react';
// import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// const TableComponent = ({ data, handleInputChange }) => {
//   return (
//     <TableContainer component={Paper}>
//   <Table border="1" cellPadding="10" cellSpacing="0">
//     <TableHead>
//       <TableRow>
//         <TableCell>State #</TableCell>
//         <TableCell>State</TableCell>
//         <TableCell>Application Status</TableCell>
//         <TableCell>Actions</TableCell>
//       </TableRow>
//     </TableHead>
//     <TableBody>
//       {data?.[0]?.states.map((stateData, index) => (
//         <TableRow key={index}>
//           <TableCell>State #{index + 1}</TableCell>
//           <TableCell>{stateData.state || "N/A"}</TableCell>
//           <TableCell>
//             <TextField
//               variant="outlined"
//               size="small"
//               value={stateData.applicationStatus || "N/A"}
//               onChange={(e) => handleInputChange(e, index)}
//             />
//           </TableCell>
//           <TableCell>
//             {stateData.actions && stateData.actions.length > 0 ? (
//               stateData.actions.map((action, actionIndex) => (
//                 <div key={actionIndex}>
//                   <p><strong>Action:</strong> {action.action}</p>
//                   <p><strong>Next State:</strong> {action.nextState}</p>
//                   <p><strong>Roles:</strong> {action.roles.join(", ")}</p>
//                   <br/>
//                   <hr /> {/* Optional to separate each action visually */}
//                   <br/>
//                 </div>
//               ))
//             ) : (
//               "No actions available"
//             )}
//           </TableCell>
//         </TableRow>
//       ))}
//     </TableBody>
//   </Table>
// </TableContainer>

//   );
// };


// export default TableComponent;


import React from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TableComponent = ({ data, handleInputChange }) => {
  // Filter states where at least one action has "FORWARD"
  const filteredData = data[0]?.states.filter(stateData =>
    stateData.actions?.some(action => action.action === "FORWARD" || action.action === "APPROVE")
  );

  console.log(filteredData,"filteredData");
  

  return (
    
    <TableContainer component={Paper}>
      <Table border="1" cellPadding="10" cellSpacing="0">
        <TableHead>
          <TableRow>
            <TableCell>State #</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Application Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((stateData, index) => (
            <TableRow key={index}>
              <TableCell>State #{index + 1}</TableCell>
              <TableCell>
                {/* {stateData.state || "N/A"} */}
                {stateData?.actions?.[0]?.roles.join(", ")}
              </TableCell>
              <TableCell>
                {filteredData?.[index +1]?.state}
              </TableCell>
              <TableCell>
                {stateData.actions.length > 0 ? (
                  stateData.actions.map((action, actionIndex) => (
                    <div key={actionIndex}>
                      <p><strong>Action:</strong> {action.action}</p>
                      <p><strong>Next State:</strong> {action.nextState}</p>
                      <p><strong>Roles:</strong> {stateData?.actions?.[actionIndex +1]?.roles.join(", ")}</p>
                      <br/>
                      <hr />
                      <br/>
                    </div>
                  ))
                ) : (
                  "No actions available"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
