import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hamburger from "./Hamburger";
import { NotificationBell } from "./svgindex";

const TopBar = ({
  img,
  isMobile,
  logoUrl,
  onLogout,
  toggleSidebar,
  ulb,
  userDetails,
  notificationCount,
  notificationCountLoaded,
  cityOfCitizenShownBesideLogo,
  onNotificationIconClick,
  hideNotificationIconOnSomeUrlsWhenNotLoggedIn,
  sidebarOpen,
  sidebarToggle,
}) => {
  const [userData, setUserData] = useState(null);
  const userInfo = Digit.UserService.getUser()?.info || {};
  const getUserInfo = async () => {
    const tenant = Digit.ULBService.getCurrentTenantId();
    const uuid = userInfo?.uuid;
    if (uuid) {
      try {
        const usersResponse = await Digit.UserService.userSearch(tenant, { uuid: [uuid] }, {});
        setUserData(usersResponse?.user?.[0]);
      } catch (error) {
        return error;
      }
    }
  };
  const designationName =
    userData?.roles
      ?.filter((item) => item.code !== "CITIZEN")
      .map((item) => item.name)
      .join(",") || "Citizen";
  useEffect(() => {
    getUserInfo();
  }, []);
  const registrationNumber = userData?.registrationNumber ? userData?.registrationNumber : userInfo?.registrationNumber;
  const validUpto = userData?.validUpto ? userData?.validUpto : userInfo?.validUpto;
  return (
    <div className="navbar">
      <div className="center-container">
        {isMobile && <Hamburger handleClick={() => sidebarToggle(sidebarOpen)} />}
        <img className="city" id="topbar-logo" src={logoUrl} alt="Himachal" />
        {/* <h3>{cityOfCitizenShownBesideLogo}</h3> */}
        {(!validUpto || !registrationNumber) && (
          <span style={{ fontSize: "0.85rem", color: "#fff", marginLeft: '5px', textShadow: "1px 1px 2px rgba(255, 255, 255, 0.3)" }}>
            Please contact the admin team if your Registration Number or Validity is not visible.
          </span>
        )}
        <div className="RightMostTopBarOptions" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <div className="row">
              <span>
                <h3 className="mx-2 fw-bold">Registration Number - {registrationNumber || "N/A"}</h3>
              </span>
            </div>
            <div className="row">
              <p className="mx-2">Valid Upto - {validUpto?.slice(0, 10) || "N/A"}</p>
            </div>
          </div>
          <div>
            <div className="row">
              <span style={{ fontSize: 18, textAlign: "center" }}>
                <h3 className="mx-2 fw-bold">{userData?.name ? userData?.name : userInfo?.name}</h3>
              </span>
            </div>
            <div className="row">
              <p className="mx-2">{designationName}</p>
            </div>
          </div>
          {/* {!hideNotificationIconOnSomeUrlsWhenNotLoggedIn ? <div className="EventNotificationWrapper" onClick={onNotificationIconClick}>
            { notificationCountLoaded && notificationCount ? <span><p>{notificationCount}</p></span> : null }
            <NotificationBell />
          </div> : null} */}
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  img: PropTypes.string,
};

TopBar.defaultProps = {
  img: undefined,
};

export default TopBar;
