import React, { useEffect, useState } from "react";
import {
  Header,
  Card,
  RadioButtons,
  SubmitBar,
  BackButton,
  CardLabel,
  CardLabelDesc,
  CardSectionHeader,
  InfoBanner,
  Loader,
  Toast,
  CardText,
} from "@egovernments/digit-ui-react-components";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory, useLocation, Redirect } from "react-router-dom";
import { stringReplaceAll } from "../bills/routes/bill-details/utils";
import $ from "jquery";
import { makePayment } from "./payGov";
import { Modal } from "@egovernments/digit-ui-react-components";

const menuData = ["Ekosh"]

export const EKoshPayment = (props) => {
  const { state = {} } = useLocation();
  const userInfo = Digit.UserService.getUser();
  const [showToast, setShowToast] = useState(null);
  const { tenantId: __tenantId, authorization, workflow: wrkflow } = Digit.Hooks.useQueryParams();
  const paymentAmount = state?.paymentAmount;
  const { t } = useTranslation();
  const history = useHistory();

  const { pathname, search } = useLocation();
  // const menu = ["AXIS"];
  const [open, setOpen] = useState(false)
  const [encryptTrans, setEncryptTrans] = useState("")
  const [multiAccountInstr, setMultiAccountInstructionDetails] = useState("")
  const [merchantId, setMerchantId] = useState("")
  const { consumerCode, businessService } = useParams();
  // const tenantId = state?.tenantId || __tenantId || Digit.ULBService.getCurrentTenantId();
  const tenantId = Digit.ULBService.getCurrentTenantId();

  const stateTenant = Digit.ULBService.getStateId();
  const { control, handleSubmit } = useForm();
  const { data: menu, isLoading } = Digit.Hooks.useCommonMDMS(stateTenant, "DIGIT-UI", "PaymentGateway");
  const currentURL = window.location.href;
  const match = currentURL.match(/[?&]flow=([^&]+)/);
  let flow = ''
  if (match) {
    flow = match[1]
  } else {
    console.log('Flow parameter not found in the URL');
  }
  console.log('Flowmenu222222222222222',menu,menuData);

  // const handleClose = () => {
  //   setOpen(false)
  // }
  const isTcpFile = consumerCode?.includes('TCP')
  let paymentdetails

  if (flow === "FORM_12") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_12_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_12_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "wwwwwww");

  }
  else if (flow === "FORM_11") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_11_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_11_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === "FORM_26") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_26_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_26_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === "FORM_CLU") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_CLU_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_CLU_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === "FORM_NOC") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_NOC_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_NOC_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === "FORM_16") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_16_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_16_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === "FORM_MT") {
    const { data: paymentdetails1, isLoading: paymentLoading1 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_MT_FEE" },
      {}
    );
    const { data: paymentdetails2, isLoading: paymentLoading2 } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService: "BPA.FORM_MT_FEE_UD" },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length == 0) {
      paymentdetails = paymentdetails2
    }
    else {
      paymentdetails = paymentdetails1
    }
    console.log({ paymentdetails, paymentdetails1, paymentdetails2 }, "xxxxxxx");

  }
  else if (flow === '') {
    const { data: paymentdetails1, isLoading: paymentLoading } = Digit.Hooks.useFetchPayment(
      { tenantId: tenantId, consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode, businessService },
      {}
    );
    if (paymentdetails1?.Bill && paymentdetails1.Bill.length > 0) {
      paymentdetails = paymentdetails1
    }
  }


  useEffect(() => {
    if (paymentdetails?.Bill && paymentdetails.Bill.length == 0) {
      setShowToast({ key: true, label: "CS_BILL_NOT_FOUND" });
    }
  }, [paymentdetails]);
  useEffect(() => {
    localStorage.setItem("BillPaymentEnabled", "true");
  }, []);
  const { name, mobileNumber } = state;

  const billDetails = paymentdetails?.Bill ? paymentdetails?.Bill[0] : {};


  const onSubmit = async (d) => {
    const serviceType = ["FORM_12", "FORM_11", "FORM_26","FORM_34"].includes(flow) ? "subservice" : "tradelicence"

    const filterData = {
      Transaction: {
        tenantId: tenantId,
        txnAmount: paymentAmount || billDetails.totalAmount,
        // module: businessService,
         module: businessService === 'BPAREG' ? 'BPAREG' : "BPA",
        billId: billDetails.id,
        consumerCode: wrkflow === "WNS" ? stringReplaceAll(consumerCode, "+", "/") : consumerCode,
        productInfo: "Common Payment",
        gateway: "EKOSH" || d?.paymentType ,
        taxAndPayments: [
          {
            billId: billDetails.id,
            amountPaid: paymentAmount || billDetails.totalAmount,
          },
        ],
        user: {
          name: name || userInfo?.info?.name,
          mobileNumber: mobileNumber || userInfo?.info?.mobileNumber,
          tenantId: tenantId,
        },
        // success

        callbackUrl: window.location.href.includes("mcollect")
          ? `${window.location.protocol}//${window.location.host}/digit-ui/citizen/${serviceType}/payment/${consumerCode}/${tenantId}?workflow=mcollect`
          : `${window.location.protocol}//${window.location.host}/digit-ui/citizen/${serviceType}/payment/${consumerCode}/${tenantId}`,
        additionalDetails: {
          isWhatsapp: false,
        },
      },
    };

    try {
      const data = await Digit.PaymentService.createCitizenReciept(tenantId, filterData);
      const redirectUrl = data?.Transaction?.redirectUrl;
      //const redirectPaymentUrl = new URLSearchParams(redirectUrl.split('?')[0])
      const params = new URLSearchParams(redirectUrl.split('?')[1])
      const EncryptTrans = params.get("encdata").replaceAll(' ', '+')
      //const MultiAccountInstructionDtls = params.get("MultiAccountInstructionDtls").replaceAll(' ', '+')
      const merchId = params.get("merchant_code").replaceAll(' ', '+')
      setMerchantId(merchId)
      setEncryptTrans(EncryptTrans)
      //setMultiAccountInstructionDetails(MultiAccountInstructionDtls)
      setOpen(true)
      console.log({ EncryptTrans, merchId, data, redirectUrl, params }, "cccccccccccc");
      return 0;
      if (d?.paymentType == "AXIS") {
        console.log("Is Axis");
        window.location = redirectUrl;
      } else {
        // new payment gatewayfor UPYOG pay
        try {

          const gatewayParam = redirectUrl
            ?.split("?")
            ?.slice(1)
            ?.join("?")
            ?.split("&")
            ?.reduce((curr, acc) => {
              var d = acc.split("=");
              curr[d[0]] = d[1];
              return curr;
            }, {});
          var newForm = $("<form>", {
            action: gatewayParam.txURL,
            method: "POST",
            target: "_top",
          });

          const orderForNDSLPaymentSite = [
            "checksum",
            "messageType",
            "merchantId",
            "serviceId",
            "orderId",
            "customerId",
            "transactionAmount",
            "currencyCode",
            "requestDateTime",
            "successUrl",
            "failUrl",
            "additionalField1",
            "additionalField2",
            "additionalField3",
            "additionalField4",
            "additionalField5",
          ];

          // override default date for UPYOG Custom pay
          gatewayParam["requestDateTime"] = gatewayParam["requestDateTime"]?.split(new Date().getFullYear()).join(`${new Date().getFullYear()} `);

          gatewayParam["successUrl"] = redirectUrl?.split("successUrl=")?.[1]?.split("eg_pg_txnid=")?.[0] + 'eg_pg_txnid=' + gatewayParam?.orderId;
          gatewayParam["failUrl"] = redirectUrl?.split("failUrl=")?.[1]?.split("eg_pg_txnid=")?.[0] + 'eg_pg_txnid=' + gatewayParam?.orderId;
          // gatewayParam["successUrl"]= data?.Transaction?.callbackUrl;
          // gatewayParam["failUrl"]= data?.Transaction?.callbackUrl;

          // var formdata = new FormData();

          for (var key of orderForNDSLPaymentSite) {

            // formdata.append(key,gatewayParam[key]);

            newForm.append(
              $("<input>", {
                name: key,
                value: gatewayParam[key],
                // type: "hidden",
              })
            );
          }
          $(document.body).append(newForm);
          newForm.submit();

          console.log({ gatewayParam }, "gatewayParammmmm");
          // makePayment(gatewayParam.txURL,formdata);

        } catch (e) {
          console.log("Error in payment redirect ", e);
          //window.location = redirectionUrl;
        }
      }
    } catch (error) {
      let messageToShow = "CS_PAYMENT_UNKNOWN_ERROR_ON_SERVER";
      if (error.response?.data?.Errors?.[0]) {
        const { code, message } = error.response?.data?.Errors?.[0];
        messageToShow = code;
      }
      setShowToast({ key: true, label: t(messageToShow) });
    }
  };

  if (authorization === "true" && !userInfo.access_token) {
    localStorage.clear();
    sessionStorage.clear();
    return <Redirect to={`/digit-ui/citizen/login?from=${encodeURIComponent(pathname + search)}`} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <BackButton>{t("CS_COMMON_BACK")}</BackButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header>{t("PAYMENT_CS_HEADER")}</Header>
        <Card>
          <div className="payment-amount-info" style={{ marginBottom: "26px" }}>
            <CardLabel className="dark">{t("PAYMENT_CS_TOTAL_AMOUNT_DUE")}</CardLabel>
            {(billDetails?.businessService === 'BPA.FORM_26_FEE_UD' && isTcpFile) ? <CardSectionHeader> ₹ {paymentAmount || billDetails?.totalAmount} </CardSectionHeader>  : <CardSectionHeader> ₹ {paymentAmount || billDetails?.totalAmountEkosh}</CardSectionHeader>}
            {/* <CardSectionHeader> ₹ {paymentAmount || billDetails?.totalAmountEkosh}</CardSectionHeader> */}
          </div>
          <CardLabel>{t("PAYMENT_CS_SELECT_METHOD")}</CardLabel>
          {menuData?.length && (
            <Controller
              name="paymentType"
              defaultValue={menuData[0]}
              control={control}
              render={(props) => <RadioButtons selectedOption={props.value} options={menuData} onSelect={props.onChange} />}
            />
          )}
          {!showToast && <SubmitBar label={t("PAYMENT_CS_BUTTON_LABEL")} submit={true} />}
        </Card>
      </form>
      {/* <InfoBanner label={t("CS_COMMON_INFO")} text={t("CS_PAYMENT_REDIRECT_NOTICE")} /> */}
      {showToast && (
        <Toast
          error={showToast.key}
          label={t(showToast.label)}
          onClose={() => {
            setShowToast(null);
          }}
        />
      )}
      <div style={{ display: open ? 'block' : 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', background: '#fff', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.3)' }}>
          <p style={{ margin: '15px' }}>By clicking on the Submit button, you will be redirected to the Third-Party Page.</p>
          <form name="ecom" method="post" action="https://himkosh.hp.nic.in/echallan/WebPages/wrfApplicationRequest.aspx" onSubmit={handleSubmit}>
            <input type="hidden" name="encdata" value={encryptTrans} />
            {/* <input type="hidden" name="MultiAccountInstructionDtls" value={multiAccountInstr} /> */}
            <input type="hidden" name="merchant_code" value={merchantId} />
            <div style={{ textAlign: 'right' }}>
              {/* <button type="button" onClick={handleClose}>Cancel</button> */}
              <input type="submit" name="submit" value="Submit" style={{ backgroundColor: '#0E7693', color: '#fff', marginLeft: '10px', padding: '5px', borderRadius: '3px' }} />
            </div>
          </form>
        </div>
      </div>

      <div style={{
        backgroundColor: '#1F4A7C',
        color: 'white',
        fontSize: '10px',
        padding: '10px',
        textAlign: 'center',
        width: '100%',
        bottom: 0,
        position: 'fixed'
      }}>
        <p>*Disclaimer:</p>
        <p>*Refund Policy: Refunds will be returned using the original method of payment – for example, if a donation has been made by credit card, the refund will be credited by the same mode of Channel to the same credit card by the Merchant and this goes for all Pay Modes from which the customer will make the Payment.</p>

        <p>*Cancellation Policy: Once the product is dispatched, then the cancellation policy won't be entertained. Cancellation of the transaction can be only initiated by the Merchant with the consent of the user/applicant. Refund/Cancellation won't be entertained once the customer makes the payment.</p>

        <p>*Chargeback Policy: In case of any chargeback raised by the user/applicant, the merchant will submit the copy of the successful transaction receipt to defend the chargeback.</p>
      </div>


    </React.Fragment>
  );
};